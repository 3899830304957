import { makeStyles } from "@material-ui/core/styles";

import bg2 from './../../assets/images/backgroundtriangulos.png'

const useStyles = makeStyles((theme) => ({

  footer:{
    background: '#000',
    
   
    backgroundImage: `url(${bg2})`,
    backgroundSize: '100%',
    color: '#fff',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    [theme.breakpoints.only("xs")]: {
      textAlign: 'center'
    }
  },
  formpag:{
    marginTop: '2em',
    [theme.breakpoints.only("xs")]: {
      '& img':{
        width: '100%',
      }
    },
    '& img':{
      width: '70%',
      borderRadius: '10px'
    },
    '& h1':{
      fontSize: '22px'
    }  ,
    
    
  },
  Logo:{
    marginLeft: '2em',
    marginTop: '2em',
    '& img':{
      width: '45%',
    },  
    [theme.breakpoints.only("xs")]: {
      marginLeft: '0em',
      '& img':{
        width: '75%',
       
      }
    },
  }
}));
export default useStyles;
