import { makeStyles } from "@material-ui/core/styles";

import bg1 from './../../assets/images/background.png'
import bgCard from './../../assets/images/bgcard.png'
import bg2 from './../../assets/images/backgroundtriangulos.png'
import bgplesk from './../../assets/images/bgplesk.png'


const useStyles = makeStyles((theme) => ({
  Home:{
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bg1})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    fontFamily: 'Roboto'

  },
  homeContainer:{
   height: '100vh',
   [theme.breakpoints.only("xs")]: {
    height: '100%',
    textAlign: 'center',
   
   },
    '& img':{
      width: '100%'  
    },
    '& h1':{
      marginTop: '2em',
      fontSize: '2.5rem'
    }
  },
  prices:{
    width: '100%',
    height: '100%',
    background: '#fff',
    backgroundImage: `url(${bg2})`,
  },
  pricesContainer:{
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    ['@media (min-width:1500px)']:{ 
      widht: '100%'
    } 
  },
  planosBg:{
    marginTop: '3em',
    width: '250px',
    padding: '50px 15px',
    borderRadius: '8px',
    background: '#105563 ',
    border: '1px solid #2e6f95',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: "#FFF",
    textAlign: 'center',
    cursor: "pointer",
    ['@media (min-width:1500px)']:{ 

    } ,
    '& p':{
      textAlign: 'left',
      marginTop: '3em',
      lineHeight: '1.5em'
    },
    '& img':{
      marginTop: '-2em'
    },

   
    '&:hover':{
      transition: '0.5s ease-out',
      backgroundImage: `url(${bgCard})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#000',
      color: '#fff',
      border: '2px solid #0091ad',
      '& h2':{      
        color: '#e37c0d',
      },
    

    },
    "& button": {     
      width: "250px",
      height: "50px",
      border: "none",
      color: "#000",
      background: "#fff",
      borderRadius: "3px",
      transition: "ease-out 0.3s",
      outline: "none",
      fontSize: '16px',
      fontFamily: 'Righteous',
      "&:hover": {
        boxShadow: "inset 250px 0 0 0 #e37c0d",
        color: '#fff',
        cursor: "pointer",
        
      },
    },
  },
  modalEntrance:{
    width: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3em',
  },
    btnNavigate:{ 
    marginTop: '-10em',
    top: '80%',
    textAlign: 'right',
    marginRight: '2em',
    marginLeft: 'auto',

    zIndex: -1,
    '& img':{
      width: '65px'
    }
  },
  plesk:{
    marginTop: '10em',
    backgroundImage: `url(${bgplesk})`,
    height: '100vh',
    '& img':{
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.only("xs")]: {
      height: '100%',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      '& img':{
        width: '100%',
        marginBottom: '2em'
      }
    }
  }
}));
export default useStyles;