import React from 'react';
import './styles.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Home from './website/home/index'
import Navbar from './components/navbar/index'
import Footer from './components/footer/index'

import Plus from './components/planos/plus'
import Entrance from './components/planos/Entrance'
import Basic from './components/planos/Basic'
import Contato from './website/contato/index'

function Routes() {

  return (
    <>
    
    <Router> 
      <Navbar/>
      <Switch>   
      <Route path='/' exact component = {Home}/>
  
      <Route path='/plus' component = {Plus}/>
      <Route path='/entrance' component = {Entrance}/>
      <Route path='/basic' component = {Basic}/>

      
      <Route path='/contato' component ={Contato}/>
  
     
      <Route path='*' exact={true} component = {Home}/>
      </Switch>  
      <Footer/>
      
    </Router>

    </>
  );
}

export default Routes;