import React from "react";
import useStyles from "./styles";
import { Grid, Fab } from "@material-ui/core";
import Navbar from "./../../components/navbar/index";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Fade, Bounce } from "react-reveal";
// import { Fade } from "react-reveal";

import server from "./../../assets/images/server.png";
import serverPlanos from "./../../assets/images/servers.png";
import database from "./../../assets/images/database.png";
import cloud from "./../../assets/images/cloud.png";
import contrate from "./../../assets/images/contrate.png";
import pc from "./../../assets/images/pc.png";
import plesk from "./../../assets/images/plesk.png";
import plataformas from "./../../assets/images/plataformasplesk.jpg";

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.Home} id="home">
      <div className={classes.homeContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <img src={server} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1>
              Hospedagem para sites <br /> com opções de gerenciamento{" "}
            </h1>
            <p>Tenha sempre uma opção que se encaixe ao seu negócio</p>
            <br />
            <img src={pc} style={{ width: "50%" }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.prices} id="hospedagem">
        <div className={classes.pricesContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Fade left>
                <div className={classes.planosBg}>
                  <img src={serverPlanos} />
                  <h1>Hospedagem Entrance</h1>
                  <p>
                    Disco 25 GB SSD <br />
                    1 Site Transferência ILIMITADA <br />
                    Contas de E-Mail ILIMITADAS <br />
                    SSL e Criador Grátis
                    <br />
                    <br />
                  </p>
                  <div>
                    R$
                    <h2 style={{ display: "inline-block", fontSize: "28px" }}>
                      15,
                    </h2>
                    99/mês
                  </div>
                  <a href="/entrance" target="_blank">
                    <button>
                      Contrate
                      <img src={contrate} />
                    </button>
                  </a>
                </div>
              </Fade>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Fade left>
                <div className={classes.planosBg}>
                  <img src={database} />

                  <h1>Hospedagem Basic</h1>
                  <p>
                    Disco 50 GB SSD <br />
                    Domínios 2 Sites
                    <br />
                    Transferência ILIMITADA
                    <br />
                    Contas de E-Mail ILIMITADAS
                    <br />
                    SSL e Criador Grátis
                  </p>
                  <div>
                    R$
                    <h2 style={{ display: "inline-block", fontSize: "28px" }}>
                      24,
                    </h2>
                    99/mês
                  </div>
                  <a href="/basic" target="_blank">
                    <button>
                      Contrate
                      <img src={contrate} />
                    </button>
                  </a>
                </div>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Fade left>
                <div className={classes.planosBg}>
                  <img src={cloud} />
                  <h1>Hospedagem Plus</h1>
                  <p>
                    Disco 100 GB SSD <br />
                    Domínios 5 Sites
                    <br />
                    Transferência ILIMITADA
                    <br />
                    Contas de E-Mail ILIMITADAS
                    <br />
                    SSL e Criador Grátis
                  </p>
                  <div>
                    R$
                    <h2 style={{ display: "inline-block", fontSize: "28px" }}>
                      35,
                    </h2>
                    99/mês
                  </div>
                  <a href="/plus">
                    <button>
                      Contrate
                      <img src={contrate} />
                    </button>
                  </a>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.btnNavigate}>
        Whatsapp
        <br />
        <Fab style={{ background: "#2ba41c" }} aria-label="add">
          <a
            href="https://api.whatsapp.com/send?phone=5516981794700&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Regresso%20!"
            target="_blank"
          >
            <WhatsAppIcon
              style={{ fontSize: "50px", color: "#fff", marginTop: "0.15em" }}
            />
          </a>
        </Fab>
      </div>
      <br />
      <div className={classes.plesk} id="sobre">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <img src={plesk} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1>
              Tenha a melhor opção de gerenciamento <br />
              de hospedagem disponível no mercado
            </h1>
            <p>
              Crie e gerencie vários sites a partir de um único painel. Você
              também pode executar atualizações, monitorar o desempenho
     
            <br />
            <br />
         
              Amplos níveis de segurança em sistema operacional, rede e
              aplicativos.{" "}
         
            <br />
            <br />
          
              Faça mais com sua plataforma ao integrar ferramentas como DigiCert
              SSL, CloudLinux, Cloudflare, Atomicorp, NodeJs, PHP, NGINX, Let’s
              Encrypt, Git, ImunifyAV, Backup to Cloud Pro e muito mais.
          
            <br />
            <br />
         
              Economize horas com uma ferramenta poderosa para instalar,
              gerenciar, atualizar e proteger todos os sites. Inclui: teste de
              regressão automatizado alimentado por IA, clonagem, ambiente de
              preparação, recursos de backup e restauração.
          
            <br />
            <br />
         
              A única plataforma de WebOps / hospedagem que roda em todas as
              plataformas de virtualização e contêiner. Além de enormes
              provedores de nuvem como AWS, Google, Alibaba, Azure e
              DigitalOcean.
            </p>
            <Bounce up>
          <img src={plataformas}/>
          </Bounce>
          </Grid>
        
        </Grid>
      </div>
    </div>
  );
}

export default Home;
