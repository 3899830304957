import React, { useState, State } from "react";
import emailjs from "emailjs-com";
import useStyles from "./styles";
import InputMask from "react-input-mask";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import {
  AccountCircle,
  AssignmentInd,
  PhoneAndroid,
  Mail,
  Lock,
} from "@material-ui/icons";
import swal from "sweetalert";

const currencies = [
  {
    value: "R PLUS R$35,99",
    label: "Regresso Plus",
  },
];

function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_549qfy7",
      "contacto_template",
      e.target,
      "user_NcrBKnYv7A9wOlafJkdm3"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
  swal({
    title: `Cadastro enviado com sucesso!`,
    text: "Em até 72 horas você receberá um email de confirmação",
    timer: 3500,
    icon: "success",
    timerProgressBar: true,
  });
}

function Plus() {
  const [currency, setCurrency] = React.useState("R PLUS R$35,99");

  const classes = useStyles();

  return (
    <div className={classes.plusPlan}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <div>
            <form onSubmit={sendEmail} className={classes.formStyle}>
              <p>Preencha o formulário para o cadasto</p>
              <div className={classes.inputContainer}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <AccountCircle />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Digite seu nome"
                      name="name"
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <AssignmentInd />
                  </Grid>
                  <Grid item>
                    <InputMask mask="999.999.999-99">
                      {() => (
                        <TextField
                          label="CPF"
                          className={classes.textField}
                          name="cpf"
                          required="true"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PhoneAndroid />
                  </Grid>
                  <Grid item>
                    <InputMask mask="(99)9 9999 9999">
                      {() => (
                        <TextField
                          label="Telefone"
                          className={classes.textField}
                          name="phone"
                          required="true"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Mail />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.textField}
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Insira seu Email"
                      required="true"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Lock />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Senha"
                      name="pass"
                      type="password"
                      required="true"
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
              </div>
              <br />
              <br />
              <br />
              <div>
                <p>Plano selecionado </p>
                <br />
                <h1>
                  <TextField
                    id="standard-select-currency"
                    select
                    value={currency}
                    name="plano"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </h1>
                R$
                <h2>35,</h2>
                99/mês
              </div>
              <div className={classes.buttonStyle}>
                <button type="submit">Enviar solicitação</button>
              </div>
              <br></br>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.informations}>
            <h2>Disco</h2> 100 GB SSD <br />
            <h2>Domínio</h2> 5 Site <br />
            <h2>Plano com Plesk </h2> R$35,99 /mês <br />
            <h2>Plano com cPanel</h2> R$ 43,99 /mês <br />
            <h2>Transferência</h2> Ilimitada <br />
            <h2>Contas de E-Mail</h2> Ilimitada <br />
            <h2>Bases MySQL</h2> Ilimitada <br />
            <h2>Subdomínios</h2> Ilimitada
            <br />
            <h2>Linguagens</h2> PHP
            <br />
            <h2>Certificado SSL</h2> Incluso <br />
            <h2>Criador de Sites</h2> Incluso
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
export default Plus;
