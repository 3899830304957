import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Navbar: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundSize: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    position: "sticky",
    color: "#0a282e",
    top: 0,
    zIndex: 999,
    '& a':{
      color: '#0a282e',
      textDecoration: 'none'
    },
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
  NavbarBG: {
    maxWidth: "100%",
    maxHeight: "100%",
    fontFamily: "Roboto",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    position: "sticky",
    background: "#fff",
    top: -0,
    zIndex: 999,
    color: "#e37c0d",
    fontWeight: 'bold',
    transition: '0.3s',
    '& a':{
      color: '#e37c0d',
      textDecoration: 'none'
    },
    [theme.breakpoints.down("md")]: {
      top: 0,
    },
  },
  linksPainel: {
    "& a": {
      textDecoration: "none",
      color: "#000",
      "&:hover": {
        color: "#24b3cd",
        transition: "0.3s ease-out",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  logoMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& img": {
        width: "150px",
      },
    },
  },
  NavbarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    maxWidth: "100%",
  },
  navMenu: {
    display: "grid",
    gridTemplateColumns: "repeat(4, auto)",
    gridArea: "16px",
    listStyle: "none",
    textAlign: "center",
    width: "60vw",
    justifyContent: "end",
    marginRight: "2rem",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "90vh",
      position: "absolute",
      top: "80px",
      left: "-100%",
      opacity: 1,
      transition: "all 0.5s ease",
    },
  },
  navItem: {
    height: "80px",
    "&:a": {
      textAlign: "center",
      color: "red",
    },
  },

  navLinks: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "0rem 1.5rem",
    height: "100%",
    cursor: "pointer",
    textAlign: "center",
    "& a": {
      textAlign: "center",
      color: "red",
    },

    "&:hover": {
      color: "#05ccf0",
      transition: "all 0.3s ease-out",
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      maxWidth: "100%",
    },
  },
  navLinksMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
      margin: "2rem auto",
      borderRadius: "41px",
      width: "50%",
      textDecoration: "none",
      fontSize: "1.5rem",
      backgroundColor: "transparent",
      color: "#fff",
      padding: "14px 20px",
      border: "2px solid #fff",
      transition: "all 0.3s ease-out",
    },
  },
  menuIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      top: 0,
      right: 5,
      transform: "translate(-100%, 60%)",
      fontSize: "2rem",
      cursor: "pointer",
      color: "#fff",
    },
  },
  navbarLogo: {
    "& img": {
      marginLeft: "5em",
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  LogoMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  navMenuActive: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "90vh",
    position: "absolute",
    top: "75px",
    opacity: 1,
    transition: "all 0.5s ease",
    background: "#242222",
    left: 0,
    listStyle: "none",
    zIndex: "1",
  },
  styleInformation: {
    background: "red",
  },
}));
export default useStyles;
