import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import logo from "../../assets/images/icobranco.png";
import useStyles from "./styles";

import pagamento from "./../../assets/images/formapagamento.png";

import logobranco from "./../../assets/images/logobranco.png";
import face from "./../../assets/images/face.png";
import insta from "./../../assets/images/insta.png";

function Footer() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div className={classes.Logo}>
                <img src={logobranco} />
                <br />
                Siga nossas redes sociais:
                <br/>
                <br/>
                <a href="/">
                  <img src={face} style={{width: '25px', borderRadius: '15px'}} />
                </a>{"   "}
                <a href="/">
                  <img src={insta} style={{width: '25px', borderRadius: '15px'}} />
                </a>
                <br />
                <br />
                Contato: <br/>contato@regresso.com.br
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formpag}>
                <h1>Aceitamos as principais formas de pagamento</h1>
                <br />
                <img src={pagamento} />
                <br />

                <p>
                  Copyright © 2021 Regresso.
                  <br />
                  Todo o conteúdo deste site é de uso exclusivo da Regresso.
                  <br /> Proibida reprodução ou utilização a qualquer título,
                  sob as penas da lei.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Footer;
