import { makeStyles } from "@material-ui/core/styles";

import background from './../../assets/images/backgroundmodal.png'

const useStyles = makeStyles((theme) => ({
  plusPlan:{
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    fontFamily: "Nunito",
    [theme.breakpoints.down("md")]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }
  },
  formStyle: {
    textAlign: "center",
    marginTop: "2em",
    fontSize: "15px",

    "& h1": {
      fontSize: "24px",
      marginTop: "-1em",
    },
    "& p": {
      fontSize: "18px",
      marginTop: "-1em",
    },
    "& h2": {
      display: "inline-block",
      fontSize: "28px",
      marginTop: "0em",
      color: "#34a0a4",
    },
  },
  inputStyle: {
    marginLeft: "5em",
    marginRight: "auto",
    lineHeigh: "5em",
    fontSize: "18px",
    width: "300px",
    height: "30px",
  },
  inputContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "500px",
  },

  buttonStyle: {
    margin: "auto",
    paddingTop: "1em",
    zIndex: 1,
    textAlign: "center",
    position: "left",
    "& button": {
      width: "200px",
      height: "40px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      backgroundColor: "#34a0a4",
      color: "#fff",
      textAlign: "center",
      transition: "ease-out 0.5s",
      outline: "none",
      fontSize: "18px",

      "&:hover": {
        boxShadow: "inset 200px 0 0 0 #000",
        cursor: "pointer",
      },
    },
  },

  informations:{
    marginTop: '4em',
    '& h2':{
      display: "inline-block", 
      fontSize: "18px",
      marginTop: '-0.2em',
      color: '#1c3a4b'
    },
    [theme.breakpoints.down("md")]: {
      width: '70%',
      marginLeft: 'auto',
      marginRight:'auto'
    }
  },
  textField:{
    width: '50ch',
    [theme.breakpoints.down("md")]: {
      width: '30ch',
      marginLeft: '2em',
    }
  }
}));
export default useStyles;
