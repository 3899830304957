import React, { useState, useEffect } from "react";

import { Link, animateScroll as scroll } from "react-scroll";

import logo from "../../assets/images/regresso-logo.png";

import useStyles from "./styles";

function Navbar() {
  const classes = useStyles();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
    console.log(window.scrollY);
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
         <nav className={navbar ? classes.NavbarBG : classes.Navbar}>
        <div className={classes.NavbarContainer}>
          <div className={classes.logoMobile}>
            <img src={logo} />
          </div>
          <Link to="/" className={classes.navbarLogo}>
            <img src={logo} />
          </Link>

          <div className={classes.LogoMobile}>
            <img src={""} />
          </div>

          <div className={classes.menuIcon} onClick={handleClick}></div>
          <div className={classes.navMenu}>
            <a href='/'>
           
              Início
              </a>

         

            <Link
              to="hospedagem"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Hospedagem
            </Link>

            <Link
              to="sobre"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={classes.navLinks}
            >
              Sobre
            </Link>
            <a href='/contato'
            >
              Contato
            </a>

            <li>
              <a href="/" className={classes.navLinksMobile}>
                Área do lojista
              </a>
            </li>
          </div>
          <div className={classes.linksPainel}>
            <a href="https://us137-pl.valueserver.net:8443" target="_blank">
              Painel
            </a>{" "}
            <a
              style={{
                border: "solid 2px",
                borderRadius: "5px",
                marginLeft: "0.5em",
              }}
              href="https://webmail.regresso.com.br"
              target="_blank"
            >
              Webmail
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
